.progressiveBlur {
    backdrop-filter: blur(10px);
    /* background-color: rgba(255, 255, 255, 0.8); */
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 100svh;
    /* position: absolute;
    top: 0;
    left: 0;
    z-index: 100; */
}

.insider-logo {
    display: flex;
    align-items: center;
    padding: 8px 20px;
}

.insider-logo img {
    height: 32px;
    margin-right: 10px;
}

.insider-container{
    margin: 0 28rem;
    max-width: 90rem;
    gap: 1rem;
}

.insider-content-subheading-2{
    color: rgba(0, 0, 0, 0.53);
    /* font-family: "SF Pro Display"; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.04em;

}

.insider-content-subheading-1{
    color: rgba(0, 0, 0, 0.53);
    /* font-family: "SF Pro Display"; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.03em;

}


.footer{
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.37);
    padding-left: 25rem;
    padding-right: 25rem;
    padding-bottom: 1rem;

    text-align: center;
    /* font-family: "SF Pro Display"; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.04em;
}

p{
    margin-bottom: 10px;
}


.speaker-visualizer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.circle {
    width: 100px;
    height: 100px;
    background-color: black;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    transition: transform 0.1s ease;
}
audio {
    display: none;
}